import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
  AiFillYoutube,
} from "react-icons/ai";
import {
  SiBuymeacoffee,
  SiFacebook,
  SiLeaflet,
  SiMedium,
} from "react-icons/si";
import { FaLinkedinIn } from "react-icons/fa";
import DarkMode from "./DarkMode";

function Footer() {
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright">
          <h3>Designed and Developed by Ali Zahid Raja</h3>
        </Col>
        <Col md="4">
          <DarkMode />
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://github.com/alizahidraja"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillGithub />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://alizahidraja.medium.com/"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SiMedium />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://twitter.com/alizahidraja"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineTwitter />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.linkedin.com/in/alizahidraja/"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.instagram.com/alizahidrajaa/"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillInstagram />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.youtube.com/channel/UCd_xSfVcQgb7ccl61aP8wqw"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillYoutube />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.facebook.com/alizahidrajaaa"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SiFacebook />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.lensfrens.xyz/alizahidraja.lens"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SiLeaflet />
              </a>
            </li>

            <li className="social-icons">
              <a
                href="https://www.buymeacoffee.com/alizahidraja"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SiBuymeacoffee />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
